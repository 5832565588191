import Parent from '../../util/Parent';
import AuthPage from '../../components/Auth/AuthPage';
import Login from '../../components/Auth/Login';
import LoggedIn from '../../components/Auth/LoggedIn';
import Session from '../../util/Session';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'next/router';

@withRouter
@observer
export default class extends Parent {
  static propTypes = {
    router: PropTypes.object.isRequired,
  };

  static async getInitialProps(args) {
    return await Session.init(args);
  }

  render() {
    return (
      <AuthPage>
        {this.$.user && this.$.user.provider !== 'package' ? (
          <LoggedIn
            returnUri={
              !this.props.router.query.returnUriEmail ||
              this.props.router.query.returnUriEmail === this.$.user.email
                ? this.props.router.query.returnUri
                : ''
            }
            flavor="login"
          />
        ) : (
          <Login
            returnUri={this.props.router.query.returnUri}
            returnUriEmail={this.props.router.query.returnUriEmail}
          />
        )}
      </AuthPage>
    );
  }
}
