import Component from '../../util/Component';
import Router from 'next/router';
import { observer } from 'mobx-react';
import Social from './Social';
import PropTypes from 'prop-types';
import Link from 'next/link';
import PasswordInput from '../Base/PasswordInput';
import config from '../../config';
import { sanitizeRedirectUrl } from '../../util/Router';

@observer
export default class extends Component {
  static propTypes = {
    returnUri: PropTypes.string,
    returnUriEmail: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      thinking: false,
      email: props.returnUriEmail || '',
      password: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async (e) => {
    // supress default form handling
    e.preventDefault();

    // ping server
    try {
      // clear previous error
      this.setState({ emailError: '', passwordError: '', thinking: true });

      // ping server
      const rsp = await this.$.Auth.login(this.state);
      if (rsp.status >= 500) {
        // connectivity between next.js and app server
        this.setState({ thinking: false });
        this.$.ToastList.addToast({
          type: 'error',
          message: 'An unexpected error has occurred!',
        });
      } else if (!rsp.ok) {
        const body = await rsp.json();
        this.setState({ thinking: false, ...body });
      } else {
        Router.push(sanitizeRedirectUrl(this.props.returnUri, '/app/authed') || '/app/authed');
      }
    } catch (err) {
      // connectivity between browser and next.js
      this.setState({ thinking: false });
      this.$.ToastList.addToast({ type: 'error', message: `Login failed!` });
    }
  };

  render() {
    return (
      <form data-test="login-form" method="POST" onSubmit={this.onSubmit}>
        <h3>Login</h3>
        <div
          className={`form-group ${
            !!this.state.emailError || !!this.state.error ? 'has-error' : ''
          }`}
          data-test="login-form-email-group"
        >
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input
            data-test="login-form-email-input"
            className="form-input"
            name="email"
            type="email"
            id="email"
            autoComplete="username email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.onChange}
          />
          <div className="form-input-hint" data-test="login-form-email-error">
            {this.state.emailError || this.state.error}
          </div>
        </div>
        <PasswordInput
          className="mt-4"
          label="Password"
          name="password"
          value={this.state.password}
          test="login-form-password-input"
          onChange={this.onChange}
          error={this.state.passwordError}
        />
        <div className="form-group mt-8">
          <button
            data-test="login-form-button"
            className={`btn btn-primary btn-block ${
              this.state.thinking ? 'loading' : ''
            }`}
            disabled={this.state.thinking}
          >
            Login
          </button>
        </div>

        <div className="text-center py-4">
          <span>Need an account?</span>
          <Link href="/auth/signup" as="/auth/signup">
            <a className="btn btn-link">Sign up</a>
          </Link>
        </div>
        <div
          className="divider text-center"
          data-content="Other ways to login"
        />
        <br />
        <Social returnUri={this.props.returnUri} />
      </form>
    );
  }
}
