import Component from '../../util/Component';
import { resize } from '../../util/ImageResizer';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    isImpersonated: PropTypes.bool,
    impersonatorName: PropTypes.string,
    impersonatorImageUrl: PropTypes.string,
    contactType: PropTypes.oneOf(['broker', 'user']),
    onClick: PropTypes.func,
  };

  initials(name = '') {
    let parts = name.split(/\s/);
    return (
      ((parts[0] || '').toUpperCase()[0] || '') +
      ((parts[1] || '').toUpperCase()[0] || '')
    );
  }

  render() {
    let styles = {
      primary: {
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundColor: '#eee',
        textAlign: 'center',
      },
      secondary: {
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundColor: '#eee',
        textAlign: 'center',
      },
    };
    let primaryImage = this.props.imageUrl;
    let primaryUuid = null;
    let parts = [];
    if (primaryImage) {
      styles.primary.backgroundImage = `url(${resize(
        primaryImage,
        '320x320'
      )})`;
      parts = styles.primary.backgroundImage.split('/');
      primaryUuid = parts[parts.length - 1];
    }

    let secondaryImage = this.props.impersonatorImageUrl;
    let secondaryUuid = null;
    parts = [];
    if (this.props.isImpersonated) {
      styles.secondary.backgroundImage = `url(${resize(
        secondaryImage,
        '320x320'
      )})`;
      parts = styles.secondary.backgroundImage.split('/');
      secondaryUuid = parts[parts.length - 1];
    }

    return (
      <figure
        data-test="profile-img-figure"
        data-uuid={primaryUuid}
        className={`avatar avatar-${this.props.size || 'xl'} ${
          this.props.className || ''
        } ${this.props.contactType === 'broker' ? 'rounded' : 'circle'}`}
        style={styles.primary}
        alt={this.initials(this.props.name)}
        onClick={this.props.onClick}
      >
        {!primaryImage && this.initials(this.props.name)}
        {this.props.isImpersonated && (
          <figure
            data-test="profile-img-impersonating-figure"
            data-uuid={secondaryUuid}
            className="circle avatar avatar-sm avatar-icon"
            style={styles.secondary}
            alt={this.initials(this.props.impersonatorName)}
          >
            {!secondaryImage && this.initials(this.props.impersonatorName)}
          </figure>
        )}
      </figure>
    );
  }
}
