import Component from '../../util/Component';
import Avatar from './Avatar';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    nameStyle: PropTypes.object,
    name: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
    image: PropTypes.string,
    imageUrl: PropTypes.string,
    contactType: PropTypes.oneOf(['broker', 'user']),
    isImpersonated: PropTypes.bool,
    impersonatorName: PropTypes.string,
    impersonatorImageUrl: PropTypes.string,
    size: PropTypes.string,
    hideOnSmall: PropTypes.bool,
    children: PropTypes.node,
  };

  render() {
    let image = null;
    if (this.props.image) {
      image = this.props.image;
    } else if (this.props.imageUrl) {
      image = this.props.imageUrl;
    }

    return (
      <div
        className={`tile ${this.props.className || ''}`}
        style={this.props.style}
      >
        <div className="tile-icon">
          <Avatar
            size={this.props.size || 'lg'}
            name={this.props.name}
            imageUrl={image}
            contactType={this.props.contactType || 'user'}
            isImpersonated={this.props.isImpersonated}
            impersonatorName={this.props.impersonatorName}
            impersonatorImageUrl={this.props.impersonatorImageUrl}
          />
        </div>
        <div
          className={`tile-content ${this.props.hideOnSmall ? 'hide-md' : ''}`}
        >
          <div
            className="tile-title"
            style={{
              maxWidth: 300,
              lineHeight: '1.5',
            }}
          >
            <div
              className="text-large text-ellipsis text-bold text-default"
              style={this.props.nameStyle}
              data-test="contact-name"
            >
              {this.props.name}
            </div>
            {this.props.secondaryText && (
              <div
                className="text-ellipsis text-default"
                data-test="contact-email"
              >
                {this.props.secondaryText}
              </div>
            )}
            {this.props.children && (
              <div className="text-ellipsis text-default">
                {this.props.children}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
