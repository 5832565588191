import Component from '../../util/Component';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    test: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      type: 'password',
    };
  }

  onClickShowPassword = () => {
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password',
    });
  };

  render() {
    const { className = '' } = this.props;
    return (
      <div
        className={`form-group ${
          this.props.error ? 'has-error' : ''
        } ${className}`}
      >
        <label
          className="form-label"
          htmlFor={`password-input-${this.props.name}`}
        >
          {this.props.label}
        </label>
        <div className="has-icon-right">
          <input
            data-test={this.props.test}
            className="form-input"
            type={this.state.type}
            id={`password-input-${this.props.name}`}
            name={this.props.name}
            placeholder={this.props.placeholder || this.props.label}
            value={this.props.value || ''}
            readOnly={this.props.readOnly}
            onBlur={this.props.onBlur}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
          />
          <i
            className="form-icon tooltip"
            style={{ right: '.5rem' }}
            data-tooltip={`${
              this.state.type === 'password' ? 'Show' : 'Hide'
            } password`}
          >
            <FontAwesomeIcon
              data-test={`password-input-${this.props.name}-icon`}
              className="icon mb-2 c-hand"
              icon={this.state.type === 'password' ? faEyeSlash : faEye}
              onClick={this.onClickShowPassword}
            />
          </i>
        </div>
        <div
          className="form-input-hint"
          data-test={`password-input-${this.props.name}-hint`}
        >
          {this.props.error}
        </div>
      </div>
    );
  }
}
